<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar contacto' : 'Nuevo contacto']"
    class="max-w-sm md:max-w-xl"
    width="100%"
    @close="closeModal"
    @cancel="handleCancel"
    @ok="handleOk"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <a-form-item label="Grupo" name="idperioidicidad">
        <a-select v-model:value="formState.idgrupo">
          <a-select-option value="" disabled="">Seleccione un grupo</a-select-option>
          <a-select-option
            :value="grupo.idgrupo"
            v-for="grupo in grupos"
            :key="grupo.idgrupo"
            :title="grupo.denominacion"
          >
            {{ grupo.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Buscar Paciente" name="search" class="w-full">
        <a-input-search
          v-model:value="formState.search"
          @change="onChangeUsuario"
          enter-button
          @search="onSearch"
        />
      </a-form-item>

      <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :data-source="data"
          :custom-row="customRow"
          size="small"
          :pagination="false"
          :loading="isLoading"
          rowKey="idpaciente"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
        >
          <template #rangepages="{ index }">
            <span> {{ (currentPageEdit - 1) * 10 + index + 1 }} </span>
          </template>

          <template #activo="{ text: activo }">
            <span>
              <a-tag :color="activo === '1' ? 'green' : 'volcano'">
                {{ activo === "1" ? "Activado" : "Desactivado" }}
              </a-tag>
            </span>
          </template>
        </a-table>

        <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
          <div class="flex">
            <a-pagination
              v-model:current="currentPageEdit"
              v-model:pageSize="pageSize"
              :total="totalDocs"
            />

            <!-- <button class="ml-4" @click="fetchDestinatarios">
              <ReloadOutlined />
            </button> -->
          </div>
        </footer>
        <p>Total de registros: {{ totalDocs }}</p>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import DestinatarioApi from "@/api/destinatario";
import { reactive, onMounted, toRaw, ref, computed, watch } from "vue";
import { notification } from "ant-design-vue";
import { columns } from "./utilsPacientes";
export default {
  props: {
    destinatario: {
      type: Object
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["closeModal", "fetchDestinatarios", "clearSelectedArrays"],
  setup(props, { emit }) {
    const grupos = ref([]);
    const data = ref([]);
    const formRef = ref();
    const currentPageEdit = ref(1);
    const first = ref(true);
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isLoading = ref(false);
    const formState = reactive(props.destinatario);
    const isEdit = !!props.destinatario.idpaciente;
    const search = ref(props.destinatario.documento);
    formState.search = props.destinatario.documento;

    // const currentPage = props.currentPage;

    const state = reactive({
      selectedRowKeys: []
    });

    console.log(formState);

    const closeModal = () => {
      emit("closeModal");
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const onChangeUsuario = (e) => {
      search.value = e.target.value;
      getPacientes(1);
    };

    const onSearch = (value) => {
      search.value = value;
      getPacientes(1);
    };

    const getPacientes = (currentPageEdit = 1) => {
      isLoading.value = true;
      DestinatarioApi.getPaciente(currentPageEdit, search.value)
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;

          if (first.value) {
            state.selectedRowKeys.push(formState.idpaciente);
            first.value = false;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
        });
    };

    const fetchGrupos = () => {
      DestinatarioApi.getGrupo()
        .then((response) => {
          grupos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    // const clearSelectedArrays = () => {
    //   state.selectedRowKeys = [];
    //   state.rowActiveStatus = [];
    // };

    // -------- para selecionar los datos de la tabla --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          formState.idpaciente = toRaw(selectedRows[0]).idpaciente;
        }
      };
    });

    const customRow = (record) => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const filterEstablecimiento = (input, option) => {
      return option.documento.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);

          console.log(payload);
          let response = {};

          if (isEdit) {
            response = await DestinatarioApi.editOne(payload, payload.idcontacto);

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: "El destinatario ha sido modificado satisfactoriamente"
              });
            }
            emit("closeModal");
            emit("fetchDestinatarios", props.currentPage);
            emit("clearSelectedArrays");
          } else {
            response = await DestinatarioApi.addOne(payload);

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: "El destinatario ha sido registrado satisfactoriamente"
              });
            }
            emit("closeModal");
            emit("fetchDestinatarios", props.currentPage);
            emit("clearSelectedArrays");
          }
        })
        .catch((err) => console.log(err));
    };

    // watch(props.currentPage, () => {
    //   getPacientes(currentPage.value);
    // });

    // const reloadFetch = () => {
    //   fetchDestinatarios();
    //   getPacientes(currentPage);
    // };

    onMounted(() => {
      fetchGrupos();
      getPacientes(1);
    });

    const rules = {};

    return {
      closeModal,
      data,
      grupos,
      filterEstablecimiento,
      handleOk,
      columns,
      rules,
      formRef,
      // reloadFetch,
      rowSelection,
      customRow,
      onSearch,
      formState,
      currentPageEdit,
      pageSize,
      onChangeUsuario,
      totalDocs,
      handleCancel,
      isEdit,
      isLoading
    };
  }
};
</script>
