export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "DOCUMENTO",
    dataIndex: "documento",
    key: "documento",
    width: 80,
    ellipsis: true
  },
  {
    title: "CELULAR",
    dataIndex: "celular",
    key: "celular",
    width: 80,
    ellipsis: true
  },
  {
    title: "NOMBRE COMPLETO",
    dataIndex: "nombre",
    key: "nombre",
    width: 180,
    ellipsis: true
  },
  // {
  //   title: "NOMBRE GRUPO",
  //   dataIndex: "grupo",
  //   key: "grupo",
  //   width: 180,
  //   ellipsis: true
  // },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];