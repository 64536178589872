<template>
  <!-- <a-page-header @back="goBack" title="DESTINATARIOS SMS " :breadcrumb="{ routes }" class="px-0" /> -->
  <a-page-header @back="() => $router.go(-1)" title="CONTACTOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Vigilancia</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Contactos</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex mb-4">
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Acciones</legend>

      <DestinatariosAcciones
        :destinatario="destinatario"
        :isDisabled="isDisabled"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleChangeState="handleChangeState"
      />
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-left ml-2">Filtrar contactos</legend>
      <DestinatariosFiltros @fetchDestinatarios="fetchDestinatarios" />
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="data"
      :custom-row="customRow"
      size="small"
      :pagination="false"
      :loading="isLoading"
      rowKey="idcontacto"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>

    <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
      <div class="flex">
        <a-pagination
          v-model:current="currentPage"
          v-model:pageSize="pageSize"
          :total="totalDocs"
        />

        <button class="ml-4" @click="fetchDestinatarios">
          <ReloadOutlined />
        </button>
      </div>
      <p>Total de registros: {{ totalDocs }}</p>
    </footer>
    <p class="text-gray-400">Fuente: HISMINSA </p>
  </div>

  <DestinatariosaAddEdit
    v-if="isModalVisible"
    :destinatario="destinatario"
    :currentPage="currentPage"
    @closeModal="closeModal"
    @clearSelectedArrays="clearSelectedArrays"
    @fetchDestinatarios="fetchDestinatarios"
  />
</template>

<script>
import { ref, watch, onMounted, computed, reactive, toRaw, createVNode } from "vue";
import { columns } from "./utilsDestinatarios";
import router from "@/router";
import DestinatarioApi from "@/api/destinatario";
import DestinatariosFiltros from "./DestinatariosFiltros.vue";
import DestinatariosaAddEdit from "./DestinatariosaAddEdit.vue";
import DestinatariosAcciones from "./DestinatariosAcciones.vue";
import UsuariosApi from "@/api/user";
import { Modal, notification } from "ant-design-vue";
import { useStore } from "vuex";
import {
  FileAddOutlined,
  FormOutlined,
  IssuesCloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CommentOutlined,
  ReloadOutlined
} from "@ant-design/icons-vue";

export default {
  name: "AppDesatinatarios",
  components: {
    DestinatariosFiltros,
    DestinatariosAcciones,
    DestinatariosaAddEdit,
    // icons
    FileAddOutlined,
    IssuesCloseOutlined,
    CommentOutlined,
    FormOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    ReloadOutlined
  },
  setup() {
    const data = ref([]);
    const store = useStore();
    const destinatario = ref(null);
    const isModalVisible = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isLoading = ref(false);
    const isDisabled = ref(true);
    const state = reactive({
      selectedRowKeys: []
    });
    // const currentPage = ref(store.getters["destinatario/currentPage"]);
    const { establecimiento } = store.state.user.usuario;

    // const goBack = () => router.back();

    const showModal = () => {
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const fetchDestinatarios = (currentPage = 1) => {
      const filter = store.getters["destinatario/filter"];
      const search = store.getters["destinatario/search"];
      // const currentPage = store.getters["destinatario/currentPage"];

      isLoading.value = true;
      DestinatarioApi.getAll({ currentPage, filter, search })
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      store.dispatch("destinatario/cleanFilters");
      UsuariosApi.userLogAdd("Vigilancia", "Destinatarios", establecimiento);
      fetchDestinatarios(currentPage.value);
    });

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    // -------- para selecionar los datos de la tabla --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          destinatario.value = toRaw(selectedRows[0]);
        }
      };
    });

    const customRow = (record) => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const handleAdd = () => {
      destinatario.value = {
        idpaciente: "",
        idgrupo: "",
        search: "",
        documento: ""
      };
      showModal();
    };

    const handleChangeState = () => {
      const activateMsg = destinatario.value.activo === "1" ? "desactivará" : "activará";
      const actionMsg = destinatario.value.activo === "1" ? "desactivado" : "activado";

      Modal.confirm({
        title: () => "¿Estás seguro de cambiar el estado?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se ${activateMsg} el contacto ${destinatario.value.nombre}`,
        okText: () => "Sí, cambiar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const valor = destinatario.value.activo === "1" ? "0" : "1";
          DestinatarioApi.CambiarEstadoOne(toRaw(destinatario.value.idcontacto), valor)
            .then((res) => {
              fetchDestinatarios(currentPage.value);
              clearSelectedArrays();

              notification.success({
                message: "Operación exitosa",
                description: `Se ha ${actionMsg} el contacto satistactoriamente.`
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const handleEdit = () => {
      showModal();
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el contacto '${destinatario.value.nombre}'`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          DestinatarioApi.deleteOne(toRaw(destinatario.value.idcontacto))
            .then((res) => {
              fetchDestinatarios(currentPage.value);
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    watch(currentPage, () => {
      fetchDestinatarios(currentPage.value);
      // store.dispatch("destinatario/setCurrentPage", currentPage.value);
    });

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    // const reloadFetch = () => {
    //   fetchDestinatarios(currentPage.value);
    // };

    return {
      // goBack,
      // routes,
      columns,
      data,
      rowSelection,
      destinatario,
      isModalVisible,
      isLoading,
      customRow,
      closeModal,
      handleDelete,
      fetchDestinatarios,
      handleEdit,
      showModal,
      handleAdd,
      totalDocs,
      handleChangeState,
      currentPage,
      pageSize,
      // reloadFetch,
      isDisabled,
      clearSelectedArrays
    };
  }
};
</script>

<style></style>
